@import '../../../../../../styles/variables';

.wrapper {
  margin-top: 40px;

  .card {
    margin: 20px auto;

    .content {
      width: 100%;
    }
  }
}

.filter {
  margin-left: 10px;
  min-width: 350px;
}

.btnGroup {
  display: flex;
  flex-direction: row;
}

.borderBox {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 25px;
  height: 100%;
  min-height: auto;
}

.divider {
  margin: 10px 0;
}

.advancedHead {
  align-self: center;
}

.wrapperGrid {
  display: flex;
  justify-content: center;
}

@media (max-width:500px) {
  .filter {
    min-width: 90%;
    width: 90%;
  }
}