.aboveTable {
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 4px 4px 0 0;

  .actions {
    max-width: 30%;
    display: flex;
    
    .spacedButton {
      margin-left: 10px;
    }
  }
}

.icon {
  width: 20px;
  height: 20px;
}

.image {
  max-height: 30px;
  max-width: 60px;
}
