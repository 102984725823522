.noMargin {
  margin: 0 !important;
}

.w100px {
  width: 100px;
  align-self: center;
}

.rowWrapper {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.removeMargin {
  display: flex;
  flex-wrap: wrap;
}

.generalForms {
  width: 66%;
}

.generalFormRow {
  display: flex;
  flex-wrap: wrap;
}

.generalFromField {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  padding: 0 3px 20px 3px;
}

.preview {
  width: 33%;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 20px;

  :global(.fa-ticket-alt) {
    margin-top: 8px;
  }

  .title {
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .caption {
    display: inline-block;
    padding: 10px 10px 0 10px;
  }
}

.loyalty {
  display: flex;
  align-items: center;
  margin-top: 20px;

  .loyaltyMsg {
    max-width: 40%;
    margin-right: 1rem;
  }

  .loyaltyBody {
    max-width: 60%;
  }
}

.headerWrapper {
  padding-bottom: 0 !important;

  .cancelBtn {
    margin-right: 10px !important;
  }
}

.dealTypeWrapper {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.frequencyTypesWrapper {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.targetAudienceWrapper {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 500px){
  .loyalty {
    display: flex;
    align-items: center;
    margin-top: 20px;
    flex-direction: column;
    
    .loyaltyMsg {
      max-width: 100%;
      margin-bottom: 1rem;
    }
  
    .loyaltyBody {
      max-width: 100%;
    }
  }

  .rowWrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .generalForms {
    width: 100%;
  }

  .generalFromField {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 3px 20px 3px;
  }

  .preview {
    margin-top: 1rem;
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 20px;
  
    :global(.fa-ticket-alt) {
      margin-top: 8px;
    }
  
    .title {
      margin-left: 10px;
      margin-bottom: 10px;
    }
  
    .caption {
      display: inline-block;
      padding: 10px 10px 0 10px;
    }
  }
}

@media (min-width:500px) and (max-width: 800px){
  .rowWrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .generalForms {
    width: 100%;
  }

  .generalFromField {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 3px 20px 3px;
  }

  .preview {
    margin-top: 1rem;
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 20px;
  
    :global(.fa-ticket-alt) {
      margin-top: 8px;
    }
  
    .title {
      margin-left: 10px;
      margin-bottom: 10px;
    }
  
    .caption {
      display: inline-block;
      padding: 10px 10px 0 10px;
    }
  }
}
