.wrapper {
  width: 100%;
}

.serviceBlock {
  background-color: rgba(0, 0, 0, 0.04);
}

.table {
  margin-bottom: 20px;
}

.details {
  margin: 20px 0;
}
