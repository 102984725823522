.cardWrapper {
    margin-top: 20px;
  }
  
  .outerWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  
    .twotierField {
      width: 50%;
      padding :0 5px;
    }
    .twotierSwitch{
        width: 20%;
        padding: 0, 5px;
      }
      .twotierSwitch1{
        width: 80%;
        padding: 0, 5px;
      }
  }
  
  @media (max-Width:500px) {
    .outerWrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    
      .twotierField {
        width: 100%;
      }
      .twotierSwitch{
        width: 100%;
        padding: 0, 5px;
      }
    }
  }
  
  @media (min-Width:500px) and (max-Width:800px) {
    .outerWrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    
      .twotierField {
        width: 50%;
        padding: 0, 5px;
      }
    }
  }
  
  
  