//
// Main Stylesheet
//




////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// 1: Include global config
@import "config";

// 2: Include demo config (that overrides the above global config)
@import "global/layout/config";

// 3: Bootstrap Include
@import "global/integration/frameworks/bootstrap/variables";
@import "~bootstrap/scss/bootstrap";
@import "global/integration/frameworks/bootstrap/bootstrap.scss";
@import "global/integration/frameworks/react/material-ui/general";

// 3: Components include
@import "global/include";
////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY ABOVE INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// 5: Demo Configs
@import "global/layout/header/config";
@import "global/layout/brand/config";
@import "global/layout/aside/config";
@import "global/layout/footer/config";


// 6: Demo Includes
// Layout
@import "global/layout/base/base";

@import "global/layout/header/header";
@import "global/layout/header/base-mobile";
@import "global/layout/header/menu";
@import "global/layout/header/topbar";

@import "global/layout/brand/brand";

@import "global/layout/aside/aside";

@import "global/layout/content/content";

@import "global/layout/footer/footer";

#subheader-back-button {
  margin-right: 10px;
  display: none;
}
