@import '../../../../../../styles/variables';

.basicDealWrapper {
  margin-top: 20px;

  .discountTypes {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .scopeWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .divider {
    margin: 20px 0 !important;
  }
  
  .container {
    .filter {
      min-width: 150px;
      margin-right: 10px;
    }
  
    .products {
      width: 100%;
    }
  }
}
