// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Custom styles
@import "./app/styles/variables";
@import "./app/styles/layout";

.display-none {
  display: none !important;
}

table .badge {
  padding: 5px;
}
