.inputWrapper {
  margin-top: 20px;
}

.col8 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 66.6666%;
}

.col6 {
  width: 49%;
}

@media (min-width: 500px) and (max-width: 800px) {
  .col8 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

}

@media (max-width: 500px) {
  .col8 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .col6 {
    width: 100%;
  }
}