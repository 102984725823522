.cardWrapper {
  margin-top: 20px;

  .wrapper {
    padding: 10px 0 20px 0;

    .btnWrapper {
      > button {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  .infoWrapper {
    padding-left: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    
    .subInfoWrapper {
      width: 33.33%;
      padding:0 5px;
    }
  }
}

@media (min-width: 500px) and (max-width: 800px) {
  .cardWrapper {
    margin-top: 20px;
  
    .wrapper {
      padding: 10px 0 20px 0;
  
      .btnWrapper {
        > button {
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  
    .infoWrapper {
      padding-left: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      
      .subInfoWrapper {
        width: 50%;
        padding:0 5px;
      }
    }
  }
}

@media (max-width: 500px) {
  .cardWrapper {
    margin-top: 20px;
  
    .wrapper {
      padding: 10px 0 20px 0;
  
      .btnWrapper {
        > button {
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  
    .infoWrapper {
      padding-left: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      
      .subInfoWrapper {
        width: 100%;
        padding:0 5px;
      }
    }
  }
}
