.wrapper {
  margin-bottom: 20px;
}

.btn {
  margin-right: 5px;
  align-self: center;
}

.searchWrapper {
  margin-top: 20px;
  margin-bottom: 40px;
}

.tableWrapper {
  margin-top: 40px;
  margin-bottom: 20px;
}
