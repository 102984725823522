.progressWrapper {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .itemBlock {
    width: 20%;
    cursor: pointer;

    &.active {
      background: #5d78ff;
      color: #fff;
    }
  }

  .itemContent {
    padding: 12px !important;
    text-align: center;
  }

  .separatorBlock {
    width: 6%;
    height: 1px;
    background: #5a5a5a;
  }
}

.headerSpace {
  margin-bottom: 20px;
}
