.searchWrapper {
  margin-top: 20px;
}

.tableWrapper {
  margin-top: 40px;
}

.footerWrapper {
  margin-top: 20px;
}
