.productImage {
  width: 100px;
  height: 100px;
  padding:10px;
  object-fit:contain;
}

.brandStyles {
  max-width: 60px;
}
