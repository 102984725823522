@import "../../../styles/variables";

.filterWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;

  .togglePeriod {
    margin-right: 8px;
  }

  .periodBlock {
    display: flex;
    align-items: center;
    margin-right: 8px;
  
    .inputPicker {
      margin-right: 8px;
    }
  }

  .periodContainer{
    margin-right: 8px;
    .spacedButtons {
      min-width: 60px;
    }
  }
}

@media (max-width:500px) {

  .filterWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
  
    .togglePeriod {
      margin-right: 8px;
    }
  
    .periodBlock {
      display: flex;
      align-items: center;
      margin-right: 8px;
    
      .inputPicker {
        margin-right: 8px;
      }
    }
  
    .periodContainer{
      margin-right: 8px;
      margin-top: 3px;
      .spacedButtons {
        min-width: 60px;
      }
    }
  }
}

