@import '../../../../../../styles/variables';

.card {
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px 20px;
  cursor: pointer;

  &:first-child {
    border-right: transparent;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    border-left: transparent;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.active {
    border: 2px solid #0abb87;
    cursor: default;
  }

  .divider {
    margin: 10px 0;
  }
}
