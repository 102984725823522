.wrapper {
  margin-top: 40px;

  .imgWrapper {
    width: 100px;

    .img {
      display: block;
      max-height: 120px;
    }
  }

  .innerWrapper {
    margin-top: 20px;

    > div {
      margin-right: 50px;
    }

    .inputWrapper {
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }
}
