.MuiButton-root {
    font-family: "Rubik" !important; 
}

.MuiFormHelperText-root {
    font-family: "Rubik" !important;
}

.MuiFormLabel-root {
    font-family: "Rubik" !important;
}

.MuiInputBase-root {
    font-family: "Rubik" !important;
}

.MuiTableCell-root {
    font-family: "Rubik" !important;
}

.MuiTab-root {
    font-family: "Rubik" !important;
}

.MuiToggleButton-root {
    font-family: "Rubik" !important;
}

.MuiTypography-body1 {
    font-family: "Rubik" !important;
}

.MuiTypography-body2 {
    font-family: "Rubik" !important;
}

.MuiTypography-h4 {
    font-family: "Rubik" !important;
}

.MuiTypography-h5 {
    font-family: "Rubik" !important;
}

.MuiTypography-h6 {
    font-family: "Rubik" !important;
}