.innerWrapper {
  margin-top: 20px;

  > div {
    margin-right: 50px;
  }

  .inputWrapper {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
}
.productImage {
  max-width: 150px;
  max-height: 90px;
}