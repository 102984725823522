.searchWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  margin-top: 40px;
}

.salesHeader {
  margin-top: 10px;
  display: flex;
  width: 100%;
}

.checkBoxWrapper {
  display: flex;
}

.searchBoxWrapper {
  width: 25%;
}

.buttonWrapper {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

@media (max-width:800px) {
  .salesHeader {
    display: flex;
    flex-wrap: wrap;
  }

  .searchBoxWrapper {
    width: 50%
  }
  .checkBoxWrapper{
    display: flex;
    flex-wrap: wrap;
  }
}