.searchWrapper {
    margin-top: 20px;
    flex-direction: column;
  }
  
  .addSpace {
    margin: 20px 0;
  }
  
  .refundFailWrapper {
    color: red;
    font-weight: bold;
  }

  .refundedWrapper {
    color: green;
    font-weight: bold;
  }

  
    .refundButtonWrapper {
      display: flex;
      flex-direction: row;
      margin-bottom: 3px !important;
      align-items: center;
}



    .input {
      margin-right: 40px !important;
    }

  .refundButtons{
    display: flex;
    flex-direction: column;
  }

    .innerWrapper {
      display: flex;
      flex-direction: row;
    }
  
   

.colorPicker{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 6px;
  margin-right: 6px;
}

.colorPicker::-webkit-color-swatch {
  border-radius: 4px;
  border: none;
}
.colorPicker::-moz-color-swatch {
  border-radius: 4px;
  border: none;
}

.colorPicker::before{
  border: none;
}
