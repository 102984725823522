.insight_container {
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: #ffffff;
}

.insight_header {
  color: #333333;
  padding: 8px 24px 8px 8px;
  border: 2px solid #269fd8;
  border-radius: 4px;
  font-size: 20px;
  width: fit-content;
  white-space: nowrap;
}

.itemContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}

.items {
  color: #363636;
  display: flex;
  flex-direction: column;
  border: 2px solid #5e5e5e;
  border-radius: 4px;
  width: 100%;
  margin: 0px 3px;
  padding: 8px;
}

.itemData {
  position: relative;
  text-align: center;
  padding: 10px 0;
}

.tooltiptext {
  visibility: hidden;
  border: 2px solid #5e5e5e;
  color: #363636;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  padding: 0 4px;
  top: -65px;
  right: 30%;
}

.itemData:hover .tooltiptext {
  visibility: visible;
}

@media (max-width: 500px) {
  .insight_container {
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 10px;
    background-color: #ffffff;
  }

  .insight_header {
    color: #333333;
    padding: 8px 20px 8px 8px;
    border: 1px solid #269fd8;
    border-radius: 4px;
    font-size: 14px;
    width: fit-content;
    white-space: nowrap;
  }
}

.items {
  color: #363636;
  display: flex;
  flex-direction: column;
  border: 1px solid #5e5e5e;
  border-radius: 4px;
  width: 24%;
  padding: 4px;
}
