.wrapper {
  margin-top: 40px;

  &.noMargin {
    margin-top: 0;
  }
}

.btnWrapper {
  margin-top: 20px;
}

.tableWrapper {
  margin-top: 40px;
}
