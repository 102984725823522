.wrapper {
  margin-top: 40px;
}

.innerWrapper {
  margin-top: 20px;
}

.themeWrapper {
  cursor: pointer;
}
