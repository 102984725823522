.noMargin {
    margin: 0 !important;
  }
  
  .w100px {
    width: 100px;
    align-self: center;
  }
  
  .rowWrapper {
    margin-top: 20px;
  }
  
  .preview {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 20px;
  
    :global(.fa-ticket-alt) {
      margin-top: 8px;
    }
  
    .title {
      margin-left: 10px;
      margin-bottom: 10px;
    }
  
    .caption {
      display: inline-block;
      padding: 10px 10px 0 10px;
    }
  }
  
  .loyalty {
    margin-top: 20px;
  }
  
  .headerWrapper {
    padding-bottom: 0 !important;
  
    .cancelBtn {
      margin-right: 10px !important;
    }
  }
  
  .dealTypeWrapper {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .frequencyTypesWrapper {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  
  .targetAudienceWrapper {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  