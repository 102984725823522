.wrapper {
  margin-top: 40px;

  .card {
    margin: 20px auto;

    .content {
      width: 100%;
    }
  }
}

.filter {
  margin-left: 10px;
  min-width: 350px;
}