.tier {
  flex-basis: 20%;
  padding: 15px;
  background: white;
  border-radius: 3px;

  .wrapper {
    margin-top: 20px;
  }
}

.mainWrapper {
  display: flex;
}

:global(.MuiAutocomplete-popper) {
  z-index: 92 !important;
}
