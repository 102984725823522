.permissionMeta {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .roleName {
    width: 25%;
  }

  .roleDescription {
    width: 25%;
  }

  .selectGroup {
    width: 25%;

    label {
      margin-left: 10px;
    }
  }
}

.header4 {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.header6 {
  display: flex;
  justify-content: space-between;
  width: 50%;
  padding-left: 8%;
}

.bottomAction {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (min-width:500px) and (max-width:800px) {
  .permissionMeta {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  
    .roleName {
      width: 30%;
    }
  
    .roleDescription {
      width: 30%;
    }
  
    .selectGroup {
      width: 30%;
  
      label {
        margin-left: 10px;
      }
    }
  }

  .header4 {
    display: flex;
    justify-content: space-between;
    width: 70%;
  }
  
  .header6 {
    display: flex;
    justify-content: space-between;
    width: 70%;
    padding-left: 8%;
  }
}

@media (max-width:500px) {
  .permissionMeta {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  
    .roleName {
      width: 100%;
    }
  
    .roleDescription {
      width: 100%;
      margin-top: 10px;
    }
  
    .selectGroup {
      width: 100%;
      margin-top: 10px;
  
      label {
        margin-left: 10px;
      }
    }
  }

  .header4 {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .header6 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
  }
}