.searchWrapper {
  margin-top: 20px;
  flex-wrap: wrap;
}

.wrapper {
  margin-top: 40px;
}

.footerWrapper {
  margin-top: 20px;

  .btn {
    margin-right: 20px;
  }
}

.icon {
  margin-right: 10px;
}
