.wrapper {
  width: 40%;
  margin-top: 20px;
  margin-bottom: 40px;
}

@media (max-width: 500px) {
  .wrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;
  }
}

@media (min-width:500px) and (max-width: 800px) {
  .wrapper {
    width: 60%;
    margin-top: 20px;
    margin-bottom: 40px;
  }
}

.wrapper2 {
  width: 20%;
  margin-top: 0px;
  margin-bottom: 0px;
}