.cardWrapper {
  margin-top: 20px;

  .wrapper {
    padding: 10px 0 20px 0;

    .btnWrapper {
      > button {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  .infoWrapper {
    padding-left: 40px;
  }
}
