.card {
  .switch {
    margin-right: 0;
    margin-bottom: 0;
  }

  .selectWrapper {
    width: 100px;
  }
}

.wrapper {
  margin-top: 40px;

  .innerWrapper {
    margin-top: 20px;
  }
}
