.help__body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .help__form {
    width: 100%;
    max-width: 800px;

    .help__title {
        display: block;
        text-align: center;
        margin-bottom: 0.5rem;
        text-decoration: none;

        > h3 {
            margin-bottom: 0.5rem;
            font-size: 3rem;
            color: #67666e;
        }

        
    }

    .help__buttonsContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;

        .help__button{
            height: 13rem;
            width: 13rem;
            margin-left: 6rem;
            margin-right: 6rem;
            margin-bottom: 2rem;
            margin-top: 2rem;
            border-radius: 0.5rem;
            font-size: 1.5rem;
        }
    }
  }
}
