.cardWrapper {
  margin-top: 20px;

  .formControl {
    margin-top: 20px;
  }

  .tagsWrapper {
    margin-top: 20px;
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .switch {
    margin-right: 0;
    margin-bottom: 0;
  }
}

.input {
  min-width: 250px;
  width: 100%;
  max-width: 300px;
}


