.wrapper {
  margin-top: 40px;
}

.buttonWrapper{
  margin-top: 20px;
}

.icon {
  margin-right: 10px;
}
