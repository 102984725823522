.wrapper {
  margin-top: 40px;
}

.innerWrapper {
  margin: 20px 0;
}

.btn {
  margin-bottom: 5px;
}

.card {
  width: 10%;
  padding-right: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.itemWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 90%;
}

.item {
  width: 33.3333%;
  padding-left: 10px;
  padding-bottom: 15px;
}

.secondRow {
  margin-top: 20px;
}

@media (max-width: 500px) {
  .card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item {
    width: 100%;
    padding-bottom: 10px;
  }

  .itemWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }
  .buttonWrapper {
    padding: 16px 32px !important;
  }
}

@media (min-width: 500px)and (max-width: 800px) {
  .item {
    width: 50%;
    padding-bottom: 15px;
  }

  .card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .itemWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }
  .buttonWrapper {
    padding: "1rem 2rem";
  }
}
