
.loadingText {
    margin-top: 2rem;
    font-size: 2rem;
    font-weight: bolder;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingText {
    padding-top: 150px;
    font-size: 60px;
}