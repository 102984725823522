.wrapper {
  margin-top: 20px;
}

.innerWrapper {
  margin-top: 20px;
}

.input {
  width: 100%;
}

.cardWrapper {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.fieldsWrapper {
  width: 49%;
}

.contactsField {
  width: 24%;
}

.formControl {
  margin: 12px 0 !important;
}

.btn {
  margin-top: 20px;
}

@media (min-width: 500px) and (max-width: 800px) {
  .contactsField {
    width: 49%;
  }
}

@media (max-width: 500px) {
  .fieldsWrapper {
    width: 100%;
  }

  .contactsField {
    width: 100%;
  }
}
