.wrapper {
  margin: 20px 0;
}

.cardWrapper {
  margin-bottom: 20px;
}

.imageBox {
  display: flex;
  min-height: 300px;
  align-items: center;
  justify-content: center;
}

.imageFrame {
  max-width: 100%;
}

.fileInput {
  display: none;
}
