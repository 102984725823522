.checkboxWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 20px 0;
}

.buttonWrapper {
  display: flex;
  min-height: 100px;
  align-items: center;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 66.6667%;
}

.fields {
  width: 49%;
}

.c_fields1 {
  width: 100%;
}

.c_fields2 {
  width: 49%;
}

@media (min-width: 500px) and (max-width:800px) {
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
} 

@media (max-width: 500px) {
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .fields {
    width: 100%;
  }
}