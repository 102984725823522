@import "./variables";

.navigation-link {
  .navigation-submenu {
    padding-top: 120px;
    display: none;
    position: fixed;
    top: 0;
    left: 140px;
    height: 100vh;
    width: 200px;
    background-color: #202036;
    z-index: 3;
  }

  &.item-hovered {
    .navigation-submenu {
      display: block;
    }
  }
}

.aside-menu_navigation {
  padding: 0 10px;
  font-size: 16px;
  font-weight: 600;
  list-style: none;

  .list-item {
    max-width: 100%;
    margin-bottom: 10px;

    .anchor-element {
      width: 100%;
      display: block;
      word-wrap: break-word;
      color: #d4d4d4;
    }
  }  
}

.kt-aside .kt-aside-menu {
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
      background: #FF0000;
  }
}
