.divider {
  margin: 20px 0 !important;
}

.container {
  .filter {
    min-width: 150px;
    margin-right: 10px;
  }

  .products {
    width: 100%;
  }
}
