.button {
  margin-top: 15px !important;
}

.removeBtn {
  margin-right: 20px !important;
}

.select {
  margin-bottom: 8px;
}

.buttonsWrapper {
  margin-top: 40px;
}
