.button {
  margin-top: 15px !important;
}

.removeBtn {
  margin-right: 20px !important;
}



.buttonsWrapper {
  margin-top: 40px;
}

.mainWrapper {
  display: flex;

  .avatarWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
  }
  
  .detailWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 80%;

    .detailFields {
      width: 50%;
      padding: 0 10px;
    }

    .notificationBody {
      display: flex;
      flex-wrap: wrap;
      justify-content: end;
      width: 100%;
    }

    .select {
      align-items: center;
      margin-top: 16px;
    }
  }
}



@media (max-width: 500px) {

  .mainWrapper {
    display: flex;
    flex-wrap: wrap;
  
    .avatarWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    
    .detailWrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .notificationBody {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        width: 100%;
        padding: 15px 10px 0 10px;
      }

      .detailFields{
        width: 100%;
        padding: 0 10px;
      }
    }
  }
}

@media (min-width: 500px) and (max-width: 800px) {
  .mainWrapper {
    display: flex;
    flex-wrap: wrap;
  
    .avatarWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    
    .detailWrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .detailFields{
        width: 50%;
        padding: 0 10px;
      }
    }
  }
}