.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  .toggleButtonGroup {
    &:first-child {
      margin-right: 20px;
    }
  }
}

.urgent,
.high,
.normal {
  min-height: 12px;
  height: 12px;
  min-width: 12px;
  width: 12px;
  display: inline-block;
  border-radius: 3px;
  margin-right: 5px;
}

.urgent {
  background-color: red;
}

.high {
  background-color: orange;
}

.normal {
  background-color: lightblue;
}
