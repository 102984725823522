.wrapper {
  margin-top: 40px;
}

.innerWrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}

.container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.innerContainer{
  padding-right: 25px;
}
