.wrapper {
  margin-top: 20px;
  margin-bottom: 40px;
}

.buttonWrapper {
  display: flex;
  justify-content: end;
  margin-top: 40px;
}

.addLockButtonWrapper {
  display: flex;
  justify-content: center;

}

.container{
  display: flex;
}

.innerContainer{
  padding: 30px;
  width: 50%;
}

.searchWrapper {
  margin-top: 20px;
  flex-wrap: wrap;
}

.formHelperTextStyle {
  color:  #FD397A;
  font-size:  11.25px;
  margin-left: 5px;
}

