@import '../../../../../../styles/variables';

.buttonsWrapper {
  margin-top: 40px;
  margin-bottom: 20px;

  .addBtn {
    margin-right: 10px;
  }
}

.promoCodeBox {
  width: 50%;
}

@media (max-width:600px) {
  .promoCodeBox {
    width: 80%;
  }
}
