.cardWrapper {
  margin-top: 20px;
}

.addressWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .addressField {
    width: 33.3333%;
    padding :0 5px;
  }
}

@media (max-Width:500px) {
  .addressWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  
    .addressField {
      width: 100%;
    }
  }
}

@media (min-Width:500px) and (max-Width:800px) {
  .addressWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  
    .addressField {
      width: 50%;
      padding: 0, 5px;
    }
  }
}


