.checkboxWrapper {
  height: 30%;
  display: flex;
}

.buttonWrapper {
  display: flex;
  min-height: 100px;
  align-items: center;
  justify-content: center;
}

.inputWrapper {
  margin: 10px 0;
}

.dividerWrapper {
  margin: 20px 0;
}

.col8 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 66.6666%;
}

.col6 {
  width: 49%;
}

.col3 {
  width: 24%;
}

.nameWrapper {
  display: flex;
  justify-content: space-between;
}

.nameField {
  width: 49%;
}

.fields {
  width: 32.5%;
}

.fieldsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c_i_subWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.b_i_col4 {
  width: 32.5%;
}

@media (min-width: 500px) and (max-width: 800px) {
  .col8 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .fields {
    width: 49%;
  }
}

@media (max-width: 500px) {
  .col8 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .col6 {
    width: 100%;
  }

  .fields {
    width: 100%;
  }
  
  .b_i_col4 {
    width: 100%;
  }
}
