.cardAlign {
  width: 19%;
  cursor: pointer;

  &.activeCard {
    background: #5d78ff;
    color: #fff;
  }
}

.wrapper {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 70%;
}

.cardWrapper {
  display: flex;
  justify-content: space-between;

  width: 100%;
}

.fields {
  margin-top: 20px;
}

.subFields {
  width: 100%;
}

.buttonWrapper {
   display: flex;
   min-height: 100px;
   align-items: center;
   justify-content: center;
 }

 @media (max-width:800px) {
   .wrapper {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
   }
 }

 @media (max-width: 500px) {
   .subFields {
     width: 100%;
   }
 }
