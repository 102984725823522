.expandedTable {
  .rowWrapper {
    padding: 10px 0;
  }

  .mainRow {
    font-size: 1rem;
  }

  .itemPrice {
    display: flex;
    justify-content: space-between;
    width: 40%;
  }

  .smallTable {
    width: 40%;
    margin-left: auto;
  }

  .infoCell {
    border-right: 1px solid rgba(224, 224, 224, 1);
  }

  .actionsCell {
    width: 180px;
    vertical-align: top;
    background-color: rgba(0, 0, 0, 0.04);

    .issueRefund {
      margin-bottom: 20px;
    }
  }
}
